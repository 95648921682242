<template>
	<InfoDialog
		v-model="computedValue"
		:width="width"
		:max-width="maxWidth"
		:mobile-fullscreen="$vuetify.breakpoint.xs"
		content-class="perform-dash__download-data-dialog"
	>
		<template #title>
			<div class="dialog-title-wrapper">
				<h3 class="dialog-title">{{ title }}</h3>
				<p v-if="computedDate" class="dialog-title-date">{{ computedDate }}</p>
			</div>
		</template>

		<v-form :disabled="saving" class="mt-6">
			<QBusinessTimePeriodSelect
				v-model="datePeriod"
				label="Time Period"
				:dont-reset="false"
				:allow-current="true"
				dense-switch
				earliest-date="2020-01-01"
				content-class="perform-dash__download-data-menu"
				color="#46C3B2"
			/>

			<div class="form-sections-item mt-10">
				<v-checkbox
					v-model="selectAllSections"
					:indeterminate="isIndeterminate"
					label="Select all sections"
					hide-details
					color="#46C3B2"
					class="form-sections-checkbox checkbox-main"
				/>
			</div>
			<div class="form-sections-row">
				<div v-for="col in sectionsCols" :key="col.join('-')" class="form-sections-col">
					<div v-for="idx in col" :key="sections[idx].label" class="form-sections-item">
						<v-checkbox
							v-model="sections[idx].value"
							:label="sections[idx].label"
							hide-details
							color="#46C3B2"
							class="form-sections-checkbox"
						/>
					</div>
				</div>
			</div>

			<v-row dense class="mt-10">
				<v-col class="d-flex">
					<v-btn class="perform-dash-btn ml-auto" color="#000F2C" outlined @click="computedValue = false">
						Cancel
					</v-btn>
					<v-btn class="perform-dash-btn ml-4" color="#46C3B2" :disabled="!submitEnabled" :dark="submitEnabled" @click="download">
						<v-progress-circular v-if="saving" class="mr-2" left size="20" width="2" indeterminate />
						Download
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</InfoDialog>
</template>

<script>
import moment from 'moment'

import QBusinessTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue'
import InfoDialog from './InfoDialog.vue'

const config = [
	{ module: 'activate_analog_close_ratio', label: 'Total Analog Close Ratio' },
	{ module: 'activate_qlm', label: 'Quility Lead Marketplace' },
	{ module: 'activate_external_lead', label: 'External Lead Sources' },
	{ module: 'activate_submit_product_mix', label: 'Submit Product Mix' },
	{ module: 'activate_activity_report', label: 'Switchboard Activity Report' },
	{ module: 'activate_activity_tracker', label: 'Counting What Counts' },
	{ module: 'activate_production_tracking', label: 'Production Tracking' },
	{ module: 'activate_self_leadership', label: 'Self-Leadership' },
]

const dateFormat = 'MMMM DD, YYYY'

export default {
	components: {
		InfoDialog,
		QBusinessTimePeriodSelect,
	},
	props: {
		value: { type: Boolean, default: false },
		config: { type: Array, required: true },
		apiPath: { type: String, required: true },
		width: { type: String, default: '95%' },
		maxWidth: { type: String, default: '640px' },
		agent: { type: String },
		phase: { type: String },
	},
	data () {
		return {
			saving: false,
			datePeriod: {
				startDateFormat: null,
				endDateFormat: null,
				period: 'month',
				label: 'Current Month',
			},
			selectAllSections: false,
			sections: this.config.map(item => ({ ...item, value: false }))
		}
	},
	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
		agentCode () {
			return this.user.AgentCode
		},
		computedDate () {
			return moment().format(dateFormat)
		},
		sectionsCols () {
			const sectionIndexes = this.sections.map((_, idx) => idx)
			const middleIdx = Math.ceil(sectionIndexes.length / 2)
			const leftColIdx = sectionIndexes.slice(0, middleIdx)
			const rightColIdx = sectionIndexes.slice(middleIdx)
			return [leftColIdx, rightColIdx]
		},
		selectedModules () {
			return this.sections.filter(({ value }) => value)
		},
		isIndeterminate () {
			if (!this.selectedModules.length) { return false }
			return this.selectedModules.length !== this.sections.length
		},
		submitEnabled () {
			return Boolean(this.selectedModules.length) && !this.saving
		},
		title () {
			return this.phase ? `Download ${this.phase} Data` : 'Download Data'
		},
	},
	watch: {
		selectAllSections () {
			if (this.selectAllSections) {
				this.selectAllModules()
			} else {
				this.unselectAllModules()
			}
		},
	},
	methods: {
		async download () {
			if (this.saving) { return }
            this.saving = true
			const { period, startDateFormat, endDateFormat, label } = this.datePeriod
			let startDate = startDateFormat
			let endDate = endDateFormat
			if (label === 'Current Month') {
				const { beginDate, endDateFormat } = this.getBusinessMonths(moment())
				startDate = beginDate
				endDate = endDateFormat
			}
			if (label === 'Current Week') {
				const { beginDate, endDateFormat } = this.getBusinessWeeks(moment())
				startDate = beginDate
				endDate = endDateFormat
			}
			this.dispatchJob({
                method: 'GET',
                path: `/api/private/stats/perf_dash/${this.apiPath}/${this.agentCode}`,
                params: {
					itemsPerPage: 'zip',
					tables: this.selectedModules.map(item => item.module).join(','),
					startDate,
					endDate,
					period: period === 'month' ? 'Monthly' : 'Weekly',
					// agent: this.agent ?? this.agentCode, // TODO: need backend
				},
                JobName: this.phase ? `${this.phase} Tables Export` : 'Tables Export',
            })
			this.saving = false
			this.computedValue = false
		},
		selectAllModules () {
			this.sections = this.sections.map(item => ({ ...item, value: true }))
		},
		unselectAllModules () {
			this.sections = this.sections.map(item => ({ ...item, value: false }))
		},
	},
}
</script>

<style lang="scss">
@import '../style/index.scss';

.perform-dash__download-data-dialog {
	--padding-x: 3rem;

	&:not(.v-dialog--fullscreen) {
		.info-card {
			border-radius: $card-border-radius;
		}
	}

	.v-card__title {
		padding: 2.5rem var(--padding-x) 0 !important;
	}

	.v-card__text {
		padding: 0.5rem var(--padding-x) 2rem !important;
	}

	.info-card-title-btn {
		position: absolute;
		right: 0;
		top: 0;
	}

	.q-business-time-period-select {
		width: 100%;
		margin: 0;
		gap: 1.5rem 2rem;
		justify-content: space-between;

		&__switch {
			align-self: flex-end;
			max-width: 100%;
			flex: 0 0 8rem;
			margin: 0;

			.v-input--switch {
				min-width: 8rem;

				.v-label {
					font-weight: 600;
					color: $color-dark !important;
				}
	
				.v-input--switch__thumb {
					color: $color-blue !important;
				}
	
				.v-input--switch__track {
					opacity: 1 !important;
					color: $color-border !important;
				}
			}
		}

		&__select {
			max-width: 100%;
			flex: 0 0 17rem;
			margin: 0 !important;

			.v-label {
				font-weight: 600;
				color: $color-dark !important;
			}
		}
	}
}

.perform-dash__download-data-menu {
	.primary--text {
		color: $color-water-green !important;
	}

	&::-webkit-scrollbar {
		width: $scrollbar-width-chrome;
		height: $scrollbar-width-chrome;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 10px $color-border;
		border-radius: 2 * $scrollbar-width-chrome;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $color-water-green;
		border-radius: 0.5 * $scrollbar-width-chrome;
	}

	&::-webkit-scrollbar-corner {
		background: transparent;
	}

	scrollbar-color: $color-water-green $color-border;
	scrollbar-width: thin;
}

.perform-dash__download-data-dialog.perform-dash__info-dialog--mobile {
	--padding-x: 1.5rem;
}
</style>


<style lang="scss" scoped>
@import '../style/index.scss';

.perform-dash__download-data-dialog {
	.dialog-title-wrapper {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 0.25rem 2rem;
		padding-bottom: 0.5rem;
		border-bottom: 2px solid $color-border-dark;
	}

	.dialog-title {
		line-height: 1.2;
		margin-bottom: 0;
		font-size: 1.125rem;
		color: $color-water-green;
		letter-spacing: normal;
	}

	.dialog-title-date {
		line-height: 1.2;
		margin-bottom: 0;
		font-size: 0.875rem;
		color: $color-dark;
	}
	.form-sections-row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		row-gap: 0;
		column-gap: 0;
	}

	.form-sections-col {
		max-width: 100%;
		flex: 1 1 16.5rem;
	}

	.form-sections-item {
		display: flex;
		align-items: center;
		height: 3rem;
		padding-right: 0.25rem;
		border-bottom: 2px solid $color-border;
	}

	.form-sections-checkbox {
		margin-top: 0;
		padding-top: 0;
		::v-deep .v-label {
			color: $color-dark;
		}
		::v-deep .v-icon {
			color: $color-water-green !important;
		}
	}

	.checkbox-main {
		::v-deep .v-label {
			font-weight: bold;
		}
	}
}
</style>
